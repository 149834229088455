import { useEffect } from "react";
import { useHistory } from "react-router-dom";

function ScrollToTop() {
	let history = useHistory();

	useEffect(() => {
		const unlisten = history.listen(() => {
			window.scrollTo(0, 0);
		});
		return () => {
			unlisten();
		};
		// eslint-disable-next-line
	}, []);

	return null;
}

export default ScrollToTop;
