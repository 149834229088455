import React from "react";

const MainHeader = ({ text, className = "" }) => {
	return (
		<div className={`px-4 py-12 lg:py-28 ${className}`}>
			<h1 className="text-4xl sm:text-5xl font-bold text-sl-blue">
				{text}
			</h1>
		</div>
	);
};

export default MainHeader;
