import React from "react";
import ProductCardAccordion from "./ProductCardAccordion";

const SessionsAccordion = ({ sessions }) => {
	return (
		<div className="w-full px-1">
			<div className="w-full max-w-5xl rounded-2xl">
				{sessions.map((session) => (
					<ProductCardAccordion product={session} key={session.id} />
				))}
			</div>
		</div>
	);
};

export default SessionsAccordion;
