import React, { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import Spinner from "./Spinner";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const TimesArray = ({
	onChange,
	selected,
	currentDate,
	times,
	isError,
	isLoading,
}) => {
	// const [selected, setSelected] = useState(null);
	const [showTimes, setShowTimes] = useState(true);
	const [errorMessage, setErrorMessage] = useState(null);

	// useEffect(() => {
	// 	onChange(selected);
	// }, [selected, onChange]);

	const handleSelected = (selected) => {
		onChange(selected);
	};

	useEffect(() => {
		// Error handling
		if (!isLoading) {
			if (!currentDate) {
				setErrorMessage("Please select a date");
				setShowTimes(false);
			} else if (isError) {
				setErrorMessage("Network error");
				setShowTimes(false);
			} else if (times?.length === 0 || !times) {
				setErrorMessage("Not enough seats available");
				setShowTimes(false);
			} else {
				setShowTimes(true);
				setErrorMessage("");
			}
		}
	}, [currentDate, times, isError, isLoading]);

	return (
		<div className="relative">
			<div className="px-2 pl-3 py-3 h-48 w-64 sm:h-96 flex-col my-auto justify-center overflow-y-scroll shadow-inner bg-gray-50">
				{isLoading && (
					<div className="absolute flex h-full m-auto">
						<Spinner />
					</div>
				)}

				{!showTimes ? (
					<div className="flex m-auto p-4 h-full place-items-center justify-center text-gray-800">
						{errorMessage}
					</div>
				) : (
					<RadioGroup value={selected} onChange={handleSelected}>
						<RadioGroup.Label className="sr-only">
							Available Timeslots
						</RadioGroup.Label>
						<div className="space-y-2">
							{times.map((time) => (
								<RadioGroup.Option
									id={`radio-btn-timeslot-${time}`}
									key={time}
									value={time}
									className={({ active }) =>
										classNames(
											active
												? "ring-1 ring-offset-2 ring-sl-green"
												: "",
											"relative rounded-md hover:rounded-lg border border-transparent px-6 py-4 cursor-pointer flex justify-center focus:outline-none"
										)
									}
								>
									{({ checked }) => (
										<>
											<div className="flex items-center">
												<div className="text-sm z-10">
													<RadioGroup.Label
														as="p"
														className={
															checked
																? "font-medium text-white"
																: "font-medium text-gray-900"
														}
													>
														{time}
													</RadioGroup.Label>
												</div>
											</div>
											<div
												className={classNames(
													checked
														? "border-sl-green bg-sl-green"
														: "border-transparent",
													"absolute -inset-px rounded-lg border-2 pointer-events-none"
												)}
												aria-hidden="true"
											/>
										</>
									)}
								</RadioGroup.Option>
							))}
						</div>
					</RadioGroup>
				)}
			</div>
		</div>
	);
};

export default TimesArray;
