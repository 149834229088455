import React, { useState, useContext } from "react";
import { BookingContext } from "./StepsNavigation";
import { RadioGroup } from "@headlessui/react";

const options = [
	{
		name: "Pay Deposit",
		price: null,
		pay_in_full: false,
		id: "pay-deposit",
	},
	{
		name: "Pay in Full",
		price: null,
		pay_in_full: true,
		id: "pay-full",
	},
];

const PaymentRadioBox = ({ onChange, totalPrice }) => {
	const [selected, setSelected] = useState(options[0]);
	// eslint-disable-next-line
	const [paymentOptions, setPaymentOptions] = useState(options);

	let bookingContext = useContext(BookingContext);

	// TODO: Assumption can only use 1 gift card per booking online.
	const nGiftCards = bookingContext.gift_card?.is_valid ? 1 : 0;

	// Deposit amount (fetched from backend)
	paymentOptions[0].price =
		bookingContext.session_type?.deposit_req *
			bookingContext.datetime?.pax -
		nGiftCards * bookingContext.session_type?.deposit_req;
	// Total price after any discounts
	paymentOptions[1].price = totalPrice
		? totalPrice
		: bookingContext.datetime?.pax * bookingContext.session_type?.price -
		  nGiftCards * bookingContext.session_type?.price;

	const handleSelect = (v) => {
		bookingContext.handlePayInFullChange(v);
		setSelected(v);
		onChange(v);
	};

	return (
		<div className="w-full">
			<div className="max-w-sm mx-auto md:mx-0">
				<RadioGroup
					value={selected}
					onChange={(selected) => handleSelect(selected)}
				>
					<RadioGroup.Label className="sr-only">
						Payment
					</RadioGroup.Label>
					<div className="space-y-2">
						{paymentOptions.map((payment) => (
							<RadioGroup.Option
								id={`radio-btn-${payment.id}`}
								key={payment.name}
								value={payment}
								className={({ active, checked }) =>
									`${
										active
											? "ring-2 ring-offset-2 ring-offset-light-blue-300 ring-green-300 ring-opacity-60"
											: ""
									}
                  ${
						checked
							? "bg-sl-green bg-opacity-75 text-white"
							: "bg-gray-100"
					}
                    relative rounded-lg shadow-sm px-5 py-4 cursor-pointer flex focus:outline-none`
								}
							>
								{({ active, checked }) => (
									<>
										<div className="flex items-center justify-between w-full">
											<div className="flex items-center">
												<div className="text-sm">
													<RadioGroup.Label
														as="p"
														className={`font-medium  ${
															checked
																? "text-gray-900"
																: "text-gray-900"
														}`}
													>
														{payment.name}
													</RadioGroup.Label>
													<RadioGroup.Description
														as="span"
														className={`inline text-lg ${
															checked
																? "text-gray-900"
																: "text-gray-500"
														}`}
													>
														<span>
															{Number.isInteger(
																payment.price
															)
																? `$${payment.price}`
																: `$${payment.price?.toFixed(
																		2
																  )}`}
														</span>
													</RadioGroup.Description>
												</div>
											</div>
											{checked && (
												<div className="flex-shrink-0 text-white">
													<CheckIcon className="w-6 h-6" />
												</div>
											)}
										</div>
									</>
								)}
							</RadioGroup.Option>
						))}
					</div>
				</RadioGroup>
			</div>
		</div>
	);
};

function CheckIcon(props) {
	return (
		<svg viewBox="0 0 24 24" fill="none" {...props}>
			<circle cx={12} cy={12} r={12} fill="#fff" opacity="0.5" />
			<path
				d="M7 13l3 3 7-7"
				stroke="#014269"
				strokeWidth={1.5}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default PaymentRadioBox;
