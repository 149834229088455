import React from "react";

const TermsConditions = () => (
	<>
		<span>
			<span className="font-bold text-lg text-gray-800">
				Terms & Conditions
			</span>
			<p>
				1. Deposit(s) will be forfeited if cancellation is made within
				24 hours of the booking.
			</p>
			<p>
				2. Deposit(s) are transferable to future booking times when 24
				hr notice is provided.
			</p>
			<p>
				3. COVID-19: Per NSW State Government directions, you will be
				required to be double vaccinated to be eligible for our
				treatment.
			</p>
		</span>
	</>
);

export default TermsConditions;
