import React, { useEffect, useState } from "react";
import Spinner from "./Spinner";
import { useQuery } from "react-query";
import { getBookingStatus } from "../api/bookings";

const BookingConfirmation = () => {
	const [sessionId, setSessionId] = useState(null);
	const [isComplete, setIsComplete] = useState(false);

	const bookingStatus = useQuery(
		[
			"booking",
			"status",
			{
				session_id: "",
			},
		],
		() => getBookingStatus(sessionId),
		{
			enabled: !!sessionId && !isComplete,
			refetchInterval: 10 * 1000,
			onSuccess: (data) => {
				if (data?.booking_success) {
					setIsComplete(true);
				}
			},
		}
	);

	useEffect(() => {
		let params = new URLSearchParams(window.location.search);
		const session_id = params.get("session_id");

		if (session_id) {
			setSessionId(session_id);
		} else {
			setSessionId(null);
		}
	}, []);

	return (
		<div className="px-8 py-4 text-center mx-auto flex flex-col h-full my-auto">
			{bookingStatus.isLoading && (
				<>
					Please wait while we confirm your reservation...
					<Spinner />
				</>
			)}
			{bookingStatus.isError && (
				<div className="pb-28">
					<p>
						There was an error retrieving your reservation status.
						Please try refreshing the page or contact us to confirm
						your reservation.
					</p>
					<p>Error: {bookingStatus?.error?.message}</p>
				</div>
			)}
			{bookingStatus.isSuccess &&
				(bookingStatus?.data?.booking_success ? (
					<div className="pb-28 space-y-8 max-w-prose mx-auto">
						<div>
							<h2 className="text-lg font-bold mb-3">
								Reservation successful!
							</h2>
							<p>
								Please find a summary of your reservation below.
								We've also sent you a booking confirmation and
								calendar invite to your email with more details.
								Please don't hesitate to contact us with any
								questions. Looking forward to seeing you!
							</p>
						</div>
						<div>
							<h2 className="text-lg font-bold mb-4">Summary</h2>
							<p>
								<strong>Status: </strong>
								CONFIRMED
							</p>
							<p>
								<strong>Reservation ID: </strong>{" "}
								{bookingStatus.data.booking_id}
							</p>
							<p>
								<strong>Location: </strong>{" "}
								{bookingStatus.data.location}
							</p>
							<p>
								<strong>Date & Time: </strong>{" "}
								{new Date(
									bookingStatus.data.start
								).toLocaleString(undefined, {
									timeZone: bookingStatus.data.timezone,
									dateStyle: "medium",
									timeStyle: "short",
								})}
							</p>
							<p>
								<strong>Sessions Booked: </strong>{" "}
								{bookingStatus.data.booking_summary}
							</p>
							<p>
								<strong>Total: </strong>$
								{bookingStatus.data.amount_total}
							</p>
							<p>
								<strong>Amount Paid: </strong>$
								{bookingStatus.data.amount_paid}
							</p>
							<p>
								<strong>Amount Owing: </strong>$
								{bookingStatus.data.amount_owing}
							</p>
						</div>
						<div>
							<a
								href="/location"
								className="h-12 w-56 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-sl-green hover:bg-green-600 transition-colors duration-300"
							>
								Make another reservation
							</a>
						</div>
					</div>
				) : (
					<>
						Please wait while we confirm your reservation...
						<Spinner />
					</>
				))}
		</div>
	);
};

export default BookingConfirmation;
