import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { getSessionsByServiceSlug } from "../../api/sessions";
import SessionsAccordion from "../SessionsAccordion";
import Spinner from "../Spinner";
import { BookingContext } from "../StepsNavigation";
import TermsConditions from "../TermsConditions";

const SessionForm = () => {
	const serviceSlug = "teeth-whitening";
	const sessions = useQuery(["sessions", serviceSlug], () =>
		getSessionsByServiceSlug(serviceSlug)
	);
	let bookingContext = React.useContext(BookingContext);

	useEffect(() => {
		if (!bookingContext.location) {
			window.location.href = "/location";
		}
	}, [bookingContext?.location]);

	return (
		<div className="px-4 py-12 lg:py-12">
			<h1 className="text-2xl sm:text-4xl font-bold text-sl-blue">
				Choose your Session
			</h1>

			<div className="flex-2 lg:flex justify-between">
				<div className="flex lg:w-3/4 flex-wrap justify-start">
					{sessions.isLoading ? (
						<Spinner />
					) : sessions.isError ? (
						<span>Error loading session-types.</span>
					) : (
						<SessionsAccordion sessions={sessions.data} />
					)}
				</div>
				<div className="flex lg:w-1/4 text-sm lg:ml-4 px-4 pt-12 lg:pt-24 flex-wrap justify-center">
					<TermsConditions />
				</div>
			</div>
		</div>
	);
};

export default SessionForm;
