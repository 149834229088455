import React from "react";
import { useHistory } from "react-router-dom";
import steps from "../routes";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";

const StepItem = ({
	title,
	stepState,
	currentStep,
	slug,
	handleClick,
	bookingData,
}) => {
	const { accomplished, index } = stepState;
	const [value, setValue] = useState(null);

	const history = useHistory();

	const onClick = (ix) => {
		handleClick(ix);
		history.push(`/${slug}`);
	};

	useEffect(() => {
		if (Object.keys(bookingData).length > 0 && index <= currentStep) {
			const propName = steps[index].propName;

			if (propName === "location") {
				setValue(bookingData[propName]?.suburb);
			}
			if (propName === "session_type") {
				setValue(bookingData[propName]?.title);
			}
			if (propName === "datetime") {
				// generate string from no. of people and datetime
				const date = moment(
					new Date(bookingData[propName]?.date)
				).format("ddd, D MMM YYYY");
				const pax = bookingData[propName]?.pax;
				const time = bookingData[propName]?.time;
				if (date && pax && time) {
					const val = (
						<span className="leading-normal">
							<p>{`${pax} ${pax > 1 ? "People" : "Person"}`}</p>
							<p>{date}</p>
							<p>{time}</p>
						</span>
					);
					setValue(val);
				}
			}
			if (propName === "booker") {
				const val = (
					<span className="leading-normal">
						<p>{`${bookingData[propName]?.first_name} ${bookingData[propName]?.last_name}`}</p>
						<p>{`${bookingData[propName]?.email}`}</p>
						<p>{`${bookingData[propName]?.mobile}`}</p>
					</span>
				);
				setValue(val);
			}
		}
	}, [bookingData, currentStep, index]);

	return (
		<>
			{accomplished && currentStep !== index ? (
				<>
					<div
						className="flex justify-center items-center rounded-full h-7 w-7 md:h-8 md:w-8 mx-auto bg-sl-green cursor-pointer"
						key={slug}
						onClick={() => onClick(index)}
					/>
					<div className="flex absolute lg:h-40 top-12 md:top-14 justify-center w-14 lg:w-36">
						<div className="flex-col">
							<p className="font-bold text-sm lg:text-lg text-center">
								{title}
							</p>
							<div className="text-md font-medium h-10 lg:h-auto text-center invisible lg:visible pt-4 leading-normal">
								{value ? value : ""}
							</div>
						</div>
					</div>
				</>
			) : (
				// Render this tab if Step hasn't been completed
				<>
					<div
						className={`flex justify-center items-center rounded-full h-7 w-7 md:h-8 md:w-8 mx-auto ${
							index === currentStep ? "bg-sl-blue" : "bg-gray-300"
						} ${
							accomplished
								? "cursor-pointer"
								: "cursor-not-allowed"
						}`}
					>
						<div
							className={`flex justify-center items-center rounded-full h-5 w-5 md:h-6 md:w-6 m-auto ${
								index === currentStep
									? "bg-sl-blue animate-ping"
									: "bg-gray-300"
							} ${
								accomplished
									? "cursor-pointer"
									: "cursor-not-allowed"
							}`}
							onClick={() => accomplished && onClick(index)}
						/>
					</div>
					<div className="flex absolute lg:h-40 top-12 md:top-14 justify-center w-14 lg:w-36">
						<div className="flex-col">
							<p className="font-bold text-sm lg:text-lg text-center">
								{title}
							</p>
							<p className="text-md lg:text-md font-medium h-10 lg:h-auto text-center invisible lg:visible pt-4"></p>
						</div>
					</div>
				</>
			)}
			{/* </Link> */}
		</>
	);
};

export default StepItem;
