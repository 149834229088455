import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { validateCoupon } from "../api/coupons";
import { validateGiftCard } from "../api/gift-cards";
import LoadingIndicator from "../components/LoadingIndicator";
import PaymentRadioBox from "./PaymentRadioBox";
import { BookingContext } from "./StepsNavigation";

import sslSecureLogo from "../images/SL_Stripe_Payment_01.png";
import stripeLogo from "../images/stripe-white.svg";

const BookingSummary = ({ onConfirm, onCheckout, loading }) => {
	const [confirmed, setConfirmed] = useState(false);
	const [code, setCode] = useState(null);
	const [currentTotal, setCurrentTotal] = useState(null);
	const [prevTotal, setPrevTotal] = useState(null);
	const [applied, setApplied] = useState(false);
	// const [loading, setLoading] = useState(false);

	let bookingContext = useContext(BookingContext);

	let validCoupon = useMutation((data) => validateCoupon(data), {
		onSuccess: (data) => {
			if (data.is_valid) {
				toast.success(`Coupon ${data?.code} applied!`);
				setCurrentTotal(data.new_price);
				setPrevTotal(data.old_price);
				bookingContext.handleBookingChange({
					coupon: { id: data.id, code: data.code },
					gift_card: null,
				});
			} else {
				toast.error(
					"Invalid conditions or invalid coupon code entered."
				);
				setCurrentTotal(
					bookingContext.datetime.pax *
						bookingContext.session_type.price
				);
				setPrevTotal(null);
			}
		},
		onError: (error) => {
			toast.error(error?.response?.data?.message);
		},
	});

	let validGiftCard = useMutation((data) => validateGiftCard(data), {
		onSuccess: (data) => {
			toast.success(`Gift card applied!`);
			setPrevTotal(null);
			setCurrentTotal(
				bookingContext.datetime.pax *
					bookingContext.session_type.price -
					data.session_type.price
			);
			bookingContext.handleBookingChange({
				gift_card: data,
				coupon: null,
			});
		},
		onError: (error) => {
			toast.error(error.response?.data?.message);
			setCurrentTotal(
				bookingContext.datetime.pax * bookingContext.session_type.price
			);
			setPrevTotal(null);
			bookingContext.handleBookingChange({
				gift_card: null,
			});
		},
	});

	const resetConfirm = React.useCallback(
		(value) => {
			if (confirmed) {
				setConfirmed(false);
			}
		},
		[confirmed]
	);

	const handleCodeChange = (e) => {
		let value = e.target.value;
		value = value?.trim()?.toUpperCase();
		console.log("value: ", value);
		setCode(value);
		setApplied(false);
	};

	const handleApply = () => {
		// Check coupon code or gift card is valid in backend.
		if (!code) {
			//
			toast.success("Code cleared");
			setPrevTotal(null);
			setCurrentTotal(
				bookingContext.datetime.pax * bookingContext.session_type.price
			);
			bookingContext.handleBookingChange({
				gift_card: null,
			});

			setApplied(true);
			return;
		}

		if (code.split("-")[0].toUpperCase() === "GC") {
			// If it is a gift card
			validGiftCard.mutate({
				serial_number: code.split("-")[1].toUpperCase(),
				session_id: bookingContext.session_type?.id,
				customer: {
					id: bookingContext.booker?.id,
					email: bookingContext.booker?.email,
				},
			});
		} else {
			// If it is a coupon code
			validCoupon.mutate({
				code: code?.trim()?.toUpperCase(),
				pax: parseInt(bookingContext.datetime.pax),
				amount_total:
					bookingContext.datetime.pax *
					bookingContext.session_type.price,
				session_types: [
					...Array(parseInt(bookingContext.datetime.pax)),
				].map((s) => bookingContext.session_type.id),
			});
		}

		setApplied(true);
	};

	const handleClick = () => {
		if (confirmed) {
			// setLoading(true);
			onCheckout();
			// setLoading(false);
		} else {
			setConfirmed(true);
			onConfirm();
		}
	};

	useEffect(() => {
		if (bookingContext?.total_price) {
			setCurrentTotal(bookingContext.total_price);
		} else {
			setCurrentTotal(
				bookingContext?.datetime?.pax *
					bookingContext?.session_type?.price
			);
		}
		// eslint-disable-next-line
	}, []);

	return (
		<div className="bg-white shadow-lg overflow-hidden sm:rounded-lg max-w-3xl">
			{/* BOOKING DETAILS */}
			<div className="px-4 py-5 sm:px-6">
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					Booking Details
				</h3>
				<p className="mt-1 max-w-2xl text-sm text-gray-500">
					Please confirm your details below before proceeding to
					checkout.
				</p>
			</div>

			{/* EMAIL */}
			<div className="border-t border-gray-200">
				<dl>
					<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
						<dt className="text-sm font-medium text-gray-500">
							Email
						</dt>
						<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
							{bookingContext.booker?.email}
						</dd>
					</div>

					{/* LOCATION */}
					<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
						<dt className="text-sm font-medium text-gray-500">
							Location
						</dt>
						<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
							{bookingContext.location?.name}
						</dd>
					</div>

					{/* DATE & TIME */}
					<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
						<dt className="text-sm font-medium text-gray-500">
							Date and time
						</dt>
						<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
							{`${moment(bookingContext.datetime?.date).format(
								"dddd, Do MMMM YYYY"
							)} @ ${bookingContext.datetime?.time}`}
						</dd>
					</div>

					{/* SESSION-TYPE */}
					<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
						<dt className="text-sm font-medium text-gray-500">
							Session Type
						</dt>
						<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
							{`${bookingContext.session_type?.title} (${bookingContext.session_type?.duration_adv} mins)`}
						</dd>
					</div>

					{/* PAX */}
					<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
						<dt className="text-sm font-medium text-gray-500">
							Person(s) booked
						</dt>
						<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
							{bookingContext.datetime?.pax}
						</dd>
					</div>

					{/* TERMS AND CONDITIONS */}
					<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
						<dt className="text-sm font-medium text-gray-500">
							Terms and Conditions
						</dt>
						<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
							We require a $20 deposit to guarantee the booking.
							You also have the option to pay the full amount of
							the booking now to save time for later. By pressing
							the <strong>Confirm</strong> button you are
							accepting the following:
							<ol className="list-decimal list-inside pt-3">
								<li>
									Deposit(s) will be forfeited if cancellation
									is made within 24 hours of the booking.
								</li>
								<li>
									Deposit(s) are transferable to future
									booking times when 24 hr notice is provided.
								</li>
							</ol>
						</dd>
					</div>

					{/* APPLY COUPON / GIFT CARD */}
					<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
						<dt className="text-sm font-medium text-gray-500">
							Promo Code / Gift Card
						</dt>
						<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
							<div className="pb-4 pt-4 sm:pt-0">
								<label
									htmlFor="coupon"
									className="block text-sm font-medium text-gray-700"
								>
									Code
								</label>
								<div className="mt-1 flex ">
									<input
										type="text"
										title="Enter a coupon code or gift card number here."
										required
										aria-required
										onChange={handleCodeChange}
										name="coupon"
										id="coupon"
										className="w-full max-w-xs shadow-sm focus:ring-sl-green focus:border-sl-green block sm:text-sm border-gray-300 rounded-l-md pr-1"
										placeholder="Enter code..."
									/>
									<button
										id={`btn-apply-coupon`}
										className="rounded-r-md -ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 disabled:opacity-70 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-sl-green focus:border-sl-green"
										onClick={handleApply}
										disabled={applied}
									>
										Apply
									</button>
								</div>
							</div>
						</dd>
					</div>

					{/* TOTAL */}
					<div className="bg-gray-50 px-4 py-5 pb-8 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
						<dt className="text-sm font-medium text-gray-500">
							Total Price
						</dt>
						<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
							<div className="pt-2 sm:pt-0">
								<p className="flex flex-wrap items-baseline text-gray-900 space-x-3">
									<span className="text-5xl font-extrabold tracking-tight">
										{currentTotal
											? `$${currentTotal.toFixed(2)}`
											: "$0"}
									</span>
									{validCoupon.data?.is_valid &&
										prevTotal && (
											<>
												<span className="ml-1 text-3xl text-gray-600 font-semibold tracking-tight line-through">
													{`$${prevTotal.toFixed(2)}`}
												</span>
												<span className="ml-1 text-xl font-semibold">
													{`You saved $${(
														validCoupon.data
															?.old_price -
														validCoupon.data
															?.new_price
													).toFixed(2)}!`}
												</span>
											</>
										)}
								</p>
							</div>
						</dd>
					</div>

					{/* SELECT PAYMENT */}
					<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
						<dt className="text-sm font-medium text-gray-500">
							Select Payment
						</dt>
						<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
							<ul className="py-4 sm:p-0 divide-y divide-gray-200">
								<li className=" flex items-center justify-between text-sm">
									<PaymentRadioBox
										onChange={resetConfirm}
										totalPrice={currentTotal}
									/>
								</li>
							</ul>
						</dd>
					</div>
					<div className="bg-white px-4 py-5">
						<div className="flex flex-col md:flex-row py-4 md:px-4 justify-center align-middle md:justify-end md:space-x-6">
							<img
								className="h-16 w-auto mx-auto md:mx-0 pb-1 order-2 md:order-1 mt-4 md:mt-0"
								src={sslSecureLogo}
								alt="Secure Stripe Payment"
							/>
							<div className="mx-auto md:order-2">
								<button
									disabled={loading}
									id="btn-confirm-checkout"
									type="button"
									className="inline-flex h-14 w-72  justify-center items-center px-6 py-3 border border-transparent text-lg font-medium rounded-full shadow-sm text-white bg-sl-blue hover:bg-sl-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sl-blue transition-colors duration-200"
									onClick={handleClick}
								>
									{loading ? (
										<LoadingIndicator />
									) : confirmed ? (
										bookingContext.session_type?.price !==
										0 ? (
											<div className="flex flex-row h-8 pl-2 pt-0.5 justify-items-center align-text-top">
												<span className="my-auto text-md">
													Pay with
												</span>
												<img
													className="max-h-8 w-16 -mt-0.5 "
													src={stripeLogo}
													alt="Stripe"
													loading="eager"
												/>
											</div>
										) : (
											"Reserve Now"
										)
									) : (
										"Confirm Reservation"
									)}
								</button>
							</div>
						</div>
					</div>
				</dl>
			</div>
		</div>
	);
};

export default BookingSummary;
