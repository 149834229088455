import React, { useState, useEffect, createContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import steps from "../routes";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";

import StepItem from "./StepItem";
import {
  LocationForm,
  SessionForm,
  DatetimeForm,
  CustomerDetailsForm,
  Checkout,
} from "./Forms";
import { Success, Failed } from "../pages";
import ScrollToTop from "./ScrollToTop";

function getTabFromPath(tabs, path) {
  for (let tab of tabs) {
    if (path === `/${tab.slug}`) {
      return tab;
    }
  }
}

export const BookingContext = createContext({
  location: null,
  session_type: null,
  datetime: null,
  booker: null,
  id: null,
  pay_in_full: null,
  tos_accepted_at: null,
  total_price: null,
  coupon: null,
  gift_card: null,
  checkout_id: null,
  handleLocationChange: null,
  handleSessionChange: null,
  handleDatetimeChange: null,
  handleCustomerChange: null,
  handleBookingIdChange: null,
  handleTOSChange: null,
  handlePayInFullChange: null,
  handleBookingChange: null,
  handleClick: null,
});

const StepsNavigation = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [booking, setBooking] = useState({});

  let history = useHistory();

  // Handle when user clicks a tab to navigate to a different page
  const handleClick = (index) => {
    console.log("changing step...: ", index);
    setCurrentStep(index);
  };

  // TODO: Major refactor - 1 method for updating object. Another method for updating object and incrementing currentstep
  // Handle when a user selects a location on the Location Form
  const handleLocationChange = (loc) => {
    setBooking((prev) => ({ ...prev, location: loc }));
    setCurrentStep((prev) => prev + 1);
  };

  // Handle when a user selects a session-type on the session form
  const handleSessionChange = (sess) => {
    setBooking((prev) => ({ ...prev, session_type: sess }));
    setCurrentStep((prev) => prev + 1);
  };

  // Handle when a user selects a booking date and time and number of people.
  const handleDatetimeChange = (datetime) => {
    setBooking((prev) => ({ ...prev, datetime: datetime }));
    setCurrentStep((prev) => prev + 1);
  };

  // Handle when a user enters their customer details
  const handleCustomerChange = (cust) => {
    //
    setBooking((prev) => ({ ...prev, booker: cust }));
    setCurrentStep((prev) => prev + 1);
  };

  const handleBookingIdChange = (id) => {
    setBooking((prev) => ({ ...prev, id: id }));
    // setCurrentStep((prev) => prev + 1);
  };

  // Method to update bookingContext object
  const handleBookingChange = (data) => {
    setBooking((prev) => ({ ...prev, ...data }));
  };

  const handleTOSChange = (date) => {
    setBooking((prev) => ({ ...prev, tos_accepted_at: date }));
  };

  const handlePayInFullChange = (value) => {
    setBooking((prev) => ({ ...prev, pay_in_full: value }));
  };

  // Sync Navigation tabs with url path
  useEffect(() => {
    console.log("Change in history detected...");
    // TODO: Change data structure of steps to a dictionary from an object array.
    const tab = getTabFromPath(steps, history.location.pathname);

    console.log("tab", tab);

    if (tab?.id && tab?.id > 0) {
      setCurrentStep(tab.id - 1);
    } else {
      setCurrentStep(0);
      history.push("/location");
    }
  }, [history, history.location.pathname]);

  // Broadcast callbacks down to BookingContext Consumers
  // TODO: Refactor handlers into 2 handlers
  useEffect(() => {
    setBooking((prev) => ({
      ...prev,
      handleLocationChange: handleLocationChange,
      handleSessionChange: handleSessionChange,
      handleDatetimeChange: handleDatetimeChange,
      handleCustomerChange: handleCustomerChange,
      handleBookingIdChange: handleBookingIdChange,
      handleTOSChange: handleTOSChange,
      handlePayInFullChange: handlePayInFullChange,
      handleBookingChange: handleBookingChange,
      handleClick: handleClick,
    }));
  }, []);

  return (
    <BookingContext.Provider value={booking}>
      <Router>
        <div className="flex overflow-y-auto mx-auto">
          <nav
            className="flex-row py-8 h-32 lg:h-52 pl-20 md:pl-24 pr-14 w-full overflow-hidden overflow-x-auto"
            style={{ minWidth: 500 }}
          >
            <ProgressBar
              style={{ maxWidth: "80%" }}
              percent={(currentStep / (steps.length - 1)) * 100}
              height={1}
              width={"95%"}
              hasStepZero={true}
              filledBackground={"#005B84"}
            >
              {steps.map((s) => (
                <Step key={s.slug}>
                  {(stepState) => (
                    <StepItem
                      id={`${s.slug}-breadcrumb`}
                      title={s.title}
                      stepState={stepState}
                      currentStep={currentStep}
                      slug={s.slug}
                      key={s.slug}
                      handleClick={handleClick}
                      bookingData={booking}
                    />
                  )}
                </Step>
              ))}
            </ProgressBar>
          </nav>
        </div>

        <ScrollToTop />

        <Switch>
          <Route exact path="/">
            <LocationForm />
          </Route>
          <Route path="/location">
            <LocationForm />
          </Route>
          <Route path="/session">
            <SessionForm />
          </Route>
          <Route path="/datetime">
            <DatetimeForm />
          </Route>
          <Route path="/your-details">
            <CustomerDetailsForm />
          </Route>
          <Route path="/checkout">
            <Checkout />
          </Route>
          <Route path="/success">
            <Success />
          </Route>
          <Route path="/failed">
            <Failed />
          </Route>
        </Switch>
      </Router>
    </BookingContext.Provider>
  );
};

export default StepsNavigation;
