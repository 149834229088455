import moment from "moment-timezone";
import _ from "lodash";

// Timeslot size in minutes. Default 15 mins. Don't change as the DB is structured around 15 min timeslots.
const STEP = 15;

function convertToMinutes(time, tz) {
	let a = moment(time).tz(tz);
	let b = a.clone().startOf("day");
	const minutes = a.diff(b, "minutes", true);
	return minutes;
}

function convertMinutesToTime(mins) {
	let h = (mins / 60) | 0;
	const mm = (mins % 60).toString(10).padStart(2, "0");
	let A = h >= 12 ? "pm" : "am";
	if (h > 12) {
		h = h - 12;
	}
	return `${h}:${mm} ${A}`;
}

// Given an interval of n consecutive timeslots
// And an interval of size b timeslots, where n <= b,
// There will be p possible permutations where b can fit into n where:
// p = n - b + 1

/**
 *
 * @param {Array} freeSlots Array of datetime strings marking the beginning of free timeslots.
 * @param {Number} step Length of timeslots in minutes
 * @param {Number} b Required session duration in minutes
 * @returns
 */
function availableTimeSlots(freeSlots, step, b, tz) {
	// Get starting timeslot candidates that will fit the session for an individual seat.

	let free = [];

	for (let s of freeSlots) {
		free.push(convertToMinutes(s, tz));
	}

	// Make sure free is sorted in ascending order.
	free.sort((a, b) => a - b);

	// Aggregate consecutive free slots. If the aggregated interval is big enough. Save it to an array.
	let result = [];
	let openInterval = false;
	let beg = 0;
	let end = 0;

	for (let i in free) {
		let diff = 0;
		if (i < free.length - 1) {
			const next = parseInt(i) + 1;
			diff = free[next] - free[i];
		}
		const consecutive = diff === step;

		if (!openInterval) {
			beg = free[i];
			if (!consecutive) {
				if (step === b) {
					result.push(beg);
				}
			} else {
				openInterval = true;
			}
		} else if (openInterval && !consecutive) {
			openInterval = false;
			end = free[i];
			if (end - beg + step >= b) {
				let p = (end - beg) / step - b / step + 1;
				let j;
				for (j = 0; j <= p; j++) {
					result.push(beg + j * step);
				}
			}
		}
	}

	return result;
}

export function availableTimeslotsByPax(seatTimeslots, duration, pax, tz) {
	// for each seat look for intervals with large enough intervals
	// const t0 = performance.now();

	let allFreeSlots = [];

	for (let seat of Object.keys(seatTimeslots)) {
		const startTimes = seatTimeslots[seat].map((s) => s.start);
		const seatSlots = availableTimeSlots(startTimes, STEP, duration, tz);
		allFreeSlots.push(...seatSlots);
	}

	// Count occurences of each time slot and filter based on pax
	const counts = _.countBy(allFreeSlots);

	let availableTimes = [];

	for (let i of Object.keys(counts)) {
		counts[i] >= pax && availableTimes.push(i);
	}

	// convert minutes to hh:mm A
	availableTimes = availableTimes.map((mins) => convertMinutesToTime(mins));

	// const t1 = performance.now();
	// console.log("Took " + (t1 - t0) + " milliseconds");
	return availableTimes;
}
