import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import moment from "moment-timezone";
import { getSeatTimeslotsByDate } from "../../api/seat-timeslots";
import { BookingContext } from "../StepsNavigation";
import BookingDatePicker from "../BookingDatePicker";
import TermsConditions from "../TermsConditions";
import TimesArray from "../TimesArray";
import { availableTimeslotsByPax } from "../utils/checkAvailable";
import { getSeatsByLocationAndSession } from "../../api/seats";

import { groupBy } from "lodash";
import { getOpeningHoursByLocation } from "../../api/opening-hours";

// const mySlots = [
// 	"2021-07-15T09:00:00.000+10",
// 	"2021-07-15T09:15:00.000+10",
// 	"2021-07-15T09:30:00.000+10",
// 	"2021-07-15T09:45:00.000+10",
// 	"2021-07-16T10:00:00.000+10",
// 	"2021-07-16T10:15:00.000+10",
// 	"2021-07-16T10:30:00.000+10",
// 	"2021-07-16T10:45:00.000+10",
// 	"2021-07-16T11:00:00.000+10",
// ];

const ErrorMessage = ({ message }) => {
	return (
		<p className="mt-2 pl-4 text-sm text-red-500" id="email-description">
			{message}
		</p>
	);
};

const NumberOfPeople = ({ onChange, value, maxPax }) => (
	<div className="flex pt-4 md:pt-12 m-auto justify-center">
		<div className="mt-1 flex-col rounded-md justify-center">
			<div className="flex">
				<span className="inline-flex items-center px-4 py-3 rounded-l-md border border-r-0 border-gray-100 bg-gray-50 text-gray-700 shadow-md sm:text-md">
					How many people would you like to book?
				</span>
				<input
					type="number"
					name="pax"
					id="pax"
					inputMode="numeric"
					pattern="[0-9]*"
					required
					aria-required
					max={maxPax}
					min={1}
					maxLength={2}
					onChange={onChange}
					value={value}
					className="flex focus:ring-sl-blue focus:border-sl-blue w-24 font-bold max-w-sm rounded-none rounded-r-md text-lg text-center border-gray-100 text-gray-800 shadow-md"
				/>
			</div>
			<p
				className="mt-2 pl-4 text-sm text-gray-500"
				id="email-description"
			>
				{`Maximum of ${maxPax} seat(s) available per slot of this session-type.`}
			</p>
		</div>
	</div>
);

const DatetimeForm = () => {
	const [date, setDate] = useState(null);
	const [time, setTime] = useState(null);
	const [pax, setPax] = useState(1);
	const [error, setError] = useState(null);
	const [availableTimes, setAvailableTimes] = useState([]);

	let bookingContext = useContext(BookingContext);
	let history = useHistory();
	let queryClient = useQueryClient();

	let location;
	let start;
	let end;

	if (bookingContext?.location?.id) {
		location = bookingContext.location;
	}

	// Only show times in the future and at least 1 hour ahead.
	if (date) {
		const newDate = moment(new Date(date));
		const currentTime = new Date();
		if (newDate.isSame(currentTime, "day")) {
			start = moment(currentTime)
				.clone()
				.add(1, "hour")
				.endOf("minute")
				.toISOString();
		} else {
			start = moment(new Date(date)).startOf("day").toISOString();
		}
		end = moment(new Date(date)).endOf("day").toISOString();
	}

	const openingHours = useQuery(
		["opening-hours", { location: location?.id }],
		() => getOpeningHoursByLocation(location?.id),
		{
			enabled: !!location?.id,
		}
	);

	const seats = useQuery(
		[
			"seats",
			{
				location: location?.id,
				session: bookingContext.session_type?.id,
			},
		],
		() =>
			getSeatsByLocationAndSession(
				location?.id,
				bookingContext.session_type?.id
			),
		{
			enabled: !!location?.id && !!bookingContext?.session_type?.id,
		}
	);

	let seatIds;
	if (seats.data) {
		seatIds = seats.data.map((s) => s.id);
	}

	const timeslots = useQuery(
		[
			"seat-timeslots",
			{
				seatIds: seatIds,
				start: start,
				end: end,
			},
		],
		() => getSeatTimeslotsByDate(seatIds, start, end),
		{
			enabled: !!date && !!seats.data,
		}
	);

	const handleSubmit = () => {
		// Error handling
		if (!pax) {
			setError({
				type: "pax",
				message: "Please select number of pax",
			});
		} else if (!date) {
			setError({
				type: "date",
				message: "Please select a date",
			});
		} else if (!time) {
			setError({
				type: "time",
				message: "Please select a time",
			});
		} else {
			bookingContext.handleDatetimeChange({
				date: date,
				time: time,
				pax: pax,
			});
			history.push("/your-details");
		}
	};

	const handleDateChange = React.useCallback(
		(value) => {
			const tz =
				location?.timezone?.name ||
				Intl.DateTimeFormat().resolvedOptions().timeZone;
			console.log("tz: ", tz);
			if (value) {
				// react-modern-calendar-datepicker library uses 1-indexed month
				const newDate = moment.tz(
					`${value.year}-${value.month}-${value.day}`,
					"YYYY-M-D",
					tz
				);
				bookingContext.handleBookingChange({
					datetime: {
						date: newDate,
						time: time,
						pax: pax,
					},
				});
				setDate(newDate);
			}
			// eslint-disable-next-line
		},
		[location?.timezone?.name]
	);

	const handleTimeChange = React.useCallback((value) => {
		value && setTime(value);
	}, []);

	const handlePaxChange = React.useCallback((e) => {
		setPax(e.target.value);
		setTime(null);
	}, []);

	// Restore pax field
	useEffect(() => {
		if (bookingContext?.datetime?.pax) {
			setPax(bookingContext.datetime.pax);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		// console.log("Session Type changed. invalidating seats");
		queryClient.invalidateQueries("seats");
	}, [bookingContext.session_type?.id, queryClient]);

	useEffect(() => {
		if (!bookingContext.session_type || !bookingContext.location) {
			window.location.href = "/location";
		}
	}, [bookingContext?.session_type, bookingContext?.location]);

	useEffect(() => {
		if (timeslots.data && location?.timezone?.name) {
			// Convert ISO date string to local time zone string of booking location
			const tz = location.timezone.name;

			for (const slot of timeslots.data) {
				slot.start = moment(slot.start).tz(tz).format();
				slot.end = moment(slot.end).tz(tz).format();
			}

			const slotsBySeat = groupBy(timeslots.data, "seat.id");

			const results = availableTimeslotsByPax(
				slotsBySeat,
				bookingContext.session_type.duration_rec,
				pax,
				tz
			);

			setAvailableTimes(results);
		}
	}, [
		timeslots.data,
		bookingContext?.session_type,
		pax,
		location?.timezone?.name,
	]);

	return (
		<>
			<div className="px-4 py-12 lg:pb-48">
				<h1 className="text-2xl sm:text-4xl font-bold text-sl-blue">
					Choose your Date & Time
				</h1>
				{/* Pax */}
				<div className="w-auto mx-2 my-4">
					<NumberOfPeople
						onChange={handlePaxChange}
						value={pax}
						maxPax={seats.data?.length || 0}
					/>
				</div>
				<div className="flex-1 lg:flex justify-around">
					{/* Calendar */}
					<div className="flex pt-6 md:pt-12 px-2 flex-wrap justify-center">
						<BookingDatePicker
							// selectedDay={date}
							onChange={handleDateChange}
							openingHours={openingHours.data}
						/>
						{/* Timeslot Array */}
						<div className="flex-col-reverse sm:flex-wrap justify-end">
							<TimesArray
								onChange={handleTimeChange}
								selected={time}
								currentDate={date}
								times={availableTimes}
								isError={timeslots.isError}
								isLoading={timeslots.isFetching}
							/>
						</div>
					</div>

					{/* 'Next' button and T&C's */}
					<div className="flex lg:w-1/4 text-sm pt-12 lg:ml-4 p-4 lg:pt-24 flex-wrap justify-center">
						<div className="flex flex-col mb-12">
							<button
								id="btn-next-datetimepax"
								type="button"
								className="inline-flex h-12 w-60 justify-center items-center px-6 py-3 border border-transparent text-lg font-medium rounded-full shadow-sm text-white bg-sl-blue hover:bg-sl-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sl-blue transition-colors duration-200"
								onClick={handleSubmit}
							>
								Next
							</button>
							{error && <ErrorMessage message={error.message} />}
						</div>
						<div className="flex-row content-end self-end justify-end text-gray-800">
							<TermsConditions />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DatetimeForm;
