import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_URL;

// Create a new booking
export const validateCoupon = async (data) => {
	data.type = "booking";
	const res = await axios.post(`${API_URL}/coupons/validate`, data);
	return res.data;
};
