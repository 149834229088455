import React from "react";
import { useHistory } from "react-router-dom";
import { BookingContext } from "./StepsNavigation";

const ButtonLocation = ({ location }) => {
	let history = useHistory();
	let bookingContext = React.useContext(BookingContext);

	const handleClick = (location) => {
		bookingContext.handleLocationChange(location);
		history.push("/session");
	};

	return (
		<button
			id={`btn-${location.slug}`}
			className="rounded-full md:rounded-4xl h-20 md:h-36 md:mx-8 my-4 w-96 bg-white hover:bg-sl-green hover:border-sl-green border-sl-blue border-2 text-sl-blue font-bold  text-xl md:text-2xl transition-colors duration-300"
			onClick={() => handleClick(location)}
		>
			{location.suburb}
		</button>
	);
};

export default ButtonLocation;
