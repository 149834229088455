import React from "react";

const Spinner = () => {
	return (
		<div className="flex justify-center content-center items-center z-10 bg-transparent w-full h-full p-20">
			<div
				className="animate-spin transition-all duration-1000 rounded-full border-4 border-t-4 border-gray-200 h-16 w-16 z-50 bg-transparent"
				style={{ borderTopColor: "#3498db" }}
			></div>
		</div>
	);
};

export default Spinner;
