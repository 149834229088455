import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { getLocations } from "../../api/locations";
import ButtonLocation from "../ButtonLocation";
import Spinner from "../Spinner";

// const locations = [
// 	{
// 		id: 1,
// 		name: "Smile Labs Parramatta",
// 		slug: "smile-labs-parramatta",
// 		suburb: "Parramatta",
// 	},
// 	{
// 		id: 2,
// 		name: "Smile Labs Penrith",
// 		slug: "smile-labs-parramatta",
// 		suburb: "Penrith",
// 	},
// ];

const LocationForm = () => {
	const locations = useQuery("locations", getLocations);

	let history = useHistory();

	useEffect(() => {
		if (history.location.pathname !== "/location") {
			history.push("/location");
		}
	}, [history]);

	return (
		<div className="px-4 py-12 lg:py-12 sm:mb-16 md:mb-24">
			<h1 className="text-2xl sm:text-4xl font-bold text-sl-blue">
				Choose your Location
			</h1>
			<div className="flex flex-wrap justify-center px-1 py-6 lg:py-14">
				{locations.isLoading ? (
					<Spinner />
				) : locations.isError ? (
					<span>Error fetching locations.</span>
				) : (
					locations.data.map((loc) => (
						<ButtonLocation location={loc} key={loc.id} />
					))
				)}
			</div>
		</div>
	);
};

export default LocationForm;
