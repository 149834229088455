import React, { useState } from "react";
import { XIcon } from "@heroicons/react/outline";

// We accept Afterpay & ZipPay!
// Whiten Now. Pay Later. We accept Afterpay & ZipPay

export default function Banner() {
	const [visible, setVisible] = useState(true);

	return (
		<div className={`relative bg-sl-blue ${visible ? "" : "hidden"}`}>
			<div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
				<div className="pr-16 sm:text-center sm:px-16">
					<p className="font-medium text-xs text-white">
						<span className="md:hidden">
							We have officially re-opened! Reserve your seat
							today!
						</span>
						<span className="hidden md:inline">
							We have officially re-opened! Reserve your seat
							today!
						</span>
						<span className="block sm:ml-2 sm:inline-block transition transform ease-in-out duration-200 hover:translate-x-1">
							<a
								href="https://smilelabs.sydney/covid-19-update"
								className="text-white font-bold underline "
								target="_blank"
								rel="noopener noreferrer"
							>
								{" "}
								Learn more{" "}
								<span aria-hidden="true">&rarr;</span>
							</a>
						</span>
					</p>
				</div>
				<div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
					<button
						type="button"
						onClick={() => setVisible(false)}
						className="flex p-2 rounded-md hover:bg-sl-blue-light focus:outline-none focus:ring-2 focus:ring-white"
					>
						<span className="sr-only">Dismiss</span>
						<XIcon
							className="h-4 w-4 text-white"
							aria-hidden="true"
						/>
					</button>
				</div>
			</div>
		</div>
	);
}
