import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { useStripe } from "@stripe/react-stripe-js";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { useHistory } from "react-router";

import ReactPixel from "react-facebook-pixel";
import { createBooking } from "../../api/bookings";
import BookingSummary from "../BookingSummary";
import { BookingContext } from "../StepsNavigation";
import { serializeGTMData } from "../utils/serializeGTMData";

const Checkout = () => {
	let bookingContext = useContext(BookingContext);
	let history = useHistory();

	const sendDataToGTM = useGTMDispatch();
	const stripe = useStripe();

	let newBooking = useMutation((data) => createBooking(data), {
		onSuccess: async (data) => {
			const booking = bookingContext;
			booking.id = data.booking_id;
			booking.total_price = data.amount_total;
			booking.checkout_id = data.id;

			bookingContext.handleBookingChange({
				id: data.booking_id,
				total_price: data.amount_total,
				checkout_id: data.id,
			});
			if (data.amount_total === 0 || data.amount_owing === 0) {
				window.location.href =
					process.env.REACT_APP_BOOKING_SUCCESS_PAGE;
			} else {
				if (data.id && data.booking_success) {
					// Google Tag Manager
					sendDataToGTM({
						event: "pendingBookingCreated",
						value: serializeGTMData(booking),
					});
					// Facebook Pixel
					ReactPixel.track("InitiateCheckout", {
						content_category: "Beauty",
						content_type: "product",
						content_ids: [bookingContext.session_type?.slug],
						currency: "aud",
						num_items: 1,
						value: booking.total_price,
						contents: [
							{
								id: bookingContext.session_type?.slug,
								quantity: bookingContext.datetime?.pax,
							},
						],
					});

					const { error } = await stripe.redirectToCheckout({
						sessionId: data.id,
					});
					if (error) {
						console.log("Stripe Checkout redirect error: ", error);
					}
				}
			}
		},
		onError: (err) => {
			if (err.response.status === 409) {
				toast.error(
					"That timeslot is no longer available. Try choosing a different one."
				);
				bookingContext.handleClick(2);
				history.push("/datetime");
			}
		},
	});

	const createCheckoutSession = async () => {
		const tz = bookingContext?.location?.timezone?.name;

		// concatenate date and time
		const time = moment.tz(bookingContext.datetime.time, "hh:mm a", tz);
		const date = moment.tz(bookingContext.datetime.date, tz);
		const start = date.set({
			hour: time.get("hour"),
			minute: time.get("minute"),
		});

		let customer;

		customer = bookingContext.booker;

		let customers = [];
		let i = 0;
		for (i = 0; i < bookingContext.datetime.pax; i++) {
			customers.push({
				customer: null,
				session_type: bookingContext.session_type.id,
			});
		}

		const booking = {
			booker: customer.id,
			source: "Online",
			start: start.toISOString(),
			end: null,
			auto_duration: true,
			location: bookingContext.location.id,
			customers: customers,
			tos_accepted_at: bookingContext.tos_accepted_at,
			group_coupon: bookingContext.coupon?.id,
		};

		// Add booker to first seat as default
		booking.customers[0].customer = customer.id;
		booking.customers[0].gift_card = bookingContext.gift_card?.id;

		const provider = "stripe";
		const body = {
			provider: provider,
			booking: booking,
			pay_in_full: bookingContext.pay_in_full?.pay_in_full,
		};

		sendDataToGTM({
			event: "stripeCheckoutInit",
			value: serializeGTMData(bookingContext),
		});

		newBooking.mutate(body);
	};

	const handleConfirm = () => {
		bookingContext.handleTOSChange(moment(new Date()).toISOString());
	};

	const handleCheckout = async () => {
		console.log("Handling Checkout...");
		await createCheckoutSession();
	};

	useEffect(() => {
		if (
			!bookingContext.session_type ||
			!bookingContext.location ||
			!bookingContext.datetime ||
			!bookingContext?.booker
		) {
			window.location.href = "/location";
		}
	}, [
		bookingContext?.session_type,
		bookingContext?.location,
		bookingContext?.datetime,
		bookingContext?.booker,
	]);

	return (
		<div className="md:px-4 py-12 lg:py-12">
			<h1 className="text-2xl sm:text-4xl px-4 md:px-0 font-bold text-sl-blue">
				Confirm - Nearly there!
			</h1>
			<div className="flex pt-8 pb-16">
				<BookingSummary
					onConfirm={handleConfirm}
					onCheckout={handleCheckout}
					loading={newBooking.isLoading}
				/>
			</div>
		</div>
	);
};

export default Checkout;
