import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_URL;

export const getSessions = async () => {
	const res = await axios.get(
		`${API_URL}/session-types?_sort=display_order:asc`
	);
	return res.data;
};

export const getSessionsByServiceSlug = async (slug) => {
	const res = await axios.get(
		`${API_URL}/session-types?_sort=display_order:asc&service_type.slug=${slug}`
	);
	return res.data;
};
