import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_URL;

// TODO: Implement pagination and change _limit=-1 to a finite number.

export const getSeatTimeslotsByDate = async (seatIds, start, end) => {
	// Return no results if no seatIds are input
	if (seatIds.length === 0 || !start) {
		return [];
	}

	const seatFilter = seatIds.map((id) => `seat.id_in=${id}`).join("&");

	const filter = `?${seatFilter}&start_gte=${start}&end_lte=${end}&timeslot_status.name=Available`;
	const res = await axios.get(
		`${API_URL}/public/seat-timeslots${filter}&_limit=-1`
	);
	return res.data;
};
