import { QueryClient, QueryClientProvider } from "react-query";
import MainHeader from "./components/MainHeader";
import StepsNavigation from "./components/StepsNavigation";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Toaster } from "react-hot-toast";
import NavHeader from "./components/NavHeader";
import Banner from "./components/Banner";
import Footer from "./components/Footer";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import ReactPixel from "react-facebook-pixel";
import BookingConfirmation from "./components/BookingConfirmation";

const options = {
	autoConfig: true,
	debug: false,
};

ReactPixel.init(process.env.REACT_APP_FB_PIXEL, null, options);
ReactPixel.pageView();

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const queryClient = new QueryClient();

function App() {
	// Handle window refresh or tab close
	// useEffect(() => {
	// window.onbeforeunload = (event) => {
	// 	const e = event || window.event;
	// 	// Cancel the event
	// 	e.preventDefault();
	// 	if (e) {
	// 		e.returnValue = ""; // Legacy method for cross browser support
	// 	}
	// 	return ""; // Legacy method for cross browser support
	// };
	// }, []);

	const gtmParams = { id: process.env.REACT_APP_GTM_ID };

	return (
		<GTMProvider state={gtmParams}>
			<div className="App bg-gray-50 min-h-screen flex-col flex">
				<Toaster className="font-medium" />
				<Elements stripe={stripePromise}>
					<QueryClientProvider client={queryClient}>
						<Router>
							<Banner />
							<NavHeader />
							<div className="container mx-auto xl:px-24 h-full flex-1">
								<Switch>
									<Route exact path="/reservation-status">
										<MainHeader
											text="Reservation Status"
											className="text-center"
										/>
										<BookingConfirmation />
									</Route>
									<Route>
										<MainHeader text="Reserve Your Seat" />
										<StepsNavigation />
									</Route>
								</Switch>
							</div>
							<Footer />
						</Router>
					</QueryClientProvider>
				</Elements>
			</div>
		</GTMProvider>
	);
}

export default App;
