import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_URL;

// Create a new booking
export const createBooking = async (data) => {
	const res = await axios.post(`${API_URL}/bookings/checkout`, data);
	return res.data;
};

// Get booking status / confirmation
export const getBookingStatus = async (checkoutSessionID) => {
	const res = await axios.get(
		`${API_URL}/bookings/status?session_id=${checkoutSessionID}`
	);
	return res.data;
};
