import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_URL;

export const getLocations = async () => {
	const res = await axios.get(`${API_URL}/public/locations?is_active=true`);
	return res.data;
};

export const getLocationByID = async (id) => {
	const res = await axios.get(
		`${API_URL}/public/locations?is_active=true/${id}`
	);
	return res.data;
};
