import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_URL;

export const getSeatsByLocation = async (location) => {
	const filter = `?location.id=${location}`;
	const res = await axios.get(`${API_URL}/seats${filter}`);
	return res.data;
};

export const getSeatsByLocationAndSession = async (location, session) => {
	const locFilter = `location.id=${location}`;
	const sessFilter = `session_types.id=${session}`;
	const res = await axios.get(`${API_URL}/seats?${locFilter}&${sessFilter}`);
	return res.data;
};
