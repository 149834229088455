import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
// import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "norama-react-modern-calendar-datepicker/lib/DatePicker.css";
// import { Calendar, utils } from "react-modern-calendar-datepicker";
import { Calendar, utils } from "norama-react-modern-calendar-datepicker";
import { BookingContext } from "./StepsNavigation";

const daysOfWeek = {
	Mondays: 0,
	Tuesdays: 1,
	Wednesdays: 2,
	Thursdays: 3,
	Fridays: 4,
	Saturdays: 5,
	Sundays: 6,
};

const calcDisabledDays = (openingHours) => {
	let disabledDays = [];

	if (openingHours) {
		let closedDays = [];

		for (let day of Object.keys(daysOfWeek)) {
			if (!openingHours[0][day]) {
				closedDays.push(day);
			}
		}

		for (let day of closedDays) {
			let i = 0;
			for (i = 0; i <= 53; i++) {
				const startOfWeek = moment(new Date()).startOf("isoWeek");
				const startDisabled = startOfWeek.add(daysOfWeek[day], "days");
				const nextDate = startDisabled.add(7 * i, "days");

				disabledDays.push({
					year: nextDate.year(),
					month: nextDate.month() + 1,
					day: nextDate.date(),
				});
			}
		}
		return disabledDays;
	}
};

const BookingDatePicker = ({ onChange, openingHours }) => {
	const [selectedDay, setSelectedDay] = useState(null);

	const disabledDays = React.useMemo(
		() => calcDisabledDays(openingHours),
		[openingHours]
	);

	let bookingContext = useContext(BookingContext);

	const handleDisabledSelect = (disabledDay) => {
		// console.log(`Sorry, we're closed on ${disabledDay}`);
	};

	useEffect(() => {
		onChange(selectedDay);
	}, [selectedDay, onChange]);

	// Prefill data if user goes back to page.
	// NB: r-m-c-d library uses 1-indexed months
	useEffect(() => {
		if (bookingContext?.datetime) {
			// Restore date
			const date = new Date(bookingContext.datetime.date);
			setSelectedDay((prev) => ({
				...prev,
				year: date.getFullYear(),
				month: date.getMonth() + 1,
				day: date.getDate(),
			}));
		}
		// eslint-disable-next-line
	}, []);

	const maximumDate = {
		...utils().getToday(),
		year: utils().getToday().year + 1,
	};

	return (
		<Calendar
			value={selectedDay}
			onChange={setSelectedDay}
			// shouldHighlightWeekends
			colorPrimary="#8FD5A6"
			minimumDate={utils().getToday()}
			maximumDate={maximumDate}
			disabledDays={disabledDays}
			onDisabledDayError={handleDisabledSelect}
			calendarClassName="custom-calendar"
			// calendarTodayClassName="bg-blue-100"
		/>
	);
};

export default BookingDatePicker;
