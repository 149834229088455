import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import TermsConditions from "../TermsConditions";
import PersonalInfo from "./PersonalInfo";
import { BookingContext } from "../StepsNavigation";
import { createCustomer } from "../../api/customers";

const CustomerDetailsForm = () => {
	let bookingContext = useContext(BookingContext);
	let history = useHistory();

	// REACT-QUERY
	let newCustomer = useMutation((data) => createCustomer(data), {
		onError: (error) => {
			toast.error(`${error?.response?.data?.message}`);
		},
	});

	const handleSubmit = async (data) => {
		const customer = await newCustomer.mutateAsync(data);

		if (customer?.id) {
			data["id"] = customer.id;
			bookingContext.handleCustomerChange(data);
			history.push("/checkout");
		}
	};

	// Redirect to start if previous steps are incomplete
	useEffect(() => {
		if (
			!bookingContext.session_type ||
			!bookingContext.location ||
			!bookingContext.datetime
		) {
			window.location.href = "/location";
		}
	}, [
		bookingContext?.session_type,
		bookingContext?.location,
		bookingContext?.datetime,
	]);

	return (
		<div className="px-4 py-12 lg:py-12">
			<h1 className="text-2xl sm:text-4xl font-bold text-sl-blue">
				Who's getting a whiter smile?
			</h1>

			<div className="flex-2 lg:flex justify-between">
				<div className="flex w-full lg:w-3/4 flex-wrap justify-start">
					<PersonalInfo
						onSubmit={handleSubmit}
						defaultValues={bookingContext.booker}
						isLoading={newCustomer.isLoading}
					/>
				</div>
				<div className="flex lg:w-1/4 text-sm lg:ml-4 px-4 pt-12 lg:pt-24 flex-wrap justify-center">
					<TermsConditions />
				</div>
			</div>
		</div>
	);
};

export default CustomerDetailsForm;
