export function serializeGTMData(bookingContext) {
	const gtmData = {
		booking_id: bookingContext?.id,
		checkout_id: bookingContext.checkout_id,
		location: {
			id: bookingContext.location?.id,
			name: bookingContext.location?.name,
			suburb: bookingContext.location?.suburb,
			slug: bookingContext.location?.slug,
			address: bookingContext.location?.address,
			geolocation: bookingContext.location?.geolocation,
		},
		session_type: {
			id: bookingContext.session_type.id,
			title: bookingContext.session_type.title,
			price: bookingContext.session_type.price,
			deposit_req: bookingContext.session_type.deposit_req,
			duration_rec: bookingContext.session_type.duration_rec,
			duration_adv: bookingContext.session_type.duration_adv,
			popular: bookingContext.session_type.popular,
			display_order: bookingContext.session_type.display_order,
			created_at: bookingContext.session_type.created_at,
			updated_at: bookingContext.session_type.updated_at,
		},
		datetime: {
			date: bookingContext.datetime.date.toISOString(),
			time: bookingContext.datetime.time,
			pax: bookingContext.datetime.pax,
		},
		booker: bookingContext.booker,
		tos_accepted_at: bookingContext.tos_accepted_at,
		coupon: bookingContext.coupon,
		gift_card: bookingContext.gift_card,
		total_price: bookingContext.total_price,
		pay_in_full: bookingContext.pay_in_full,
	};

	return gtmData;
}
