import React from "react";

const LoadingIndicator = () => {
	return (
		<div className=" p-5 flex space-x-2">
			<div className="w-2 h-2 bg-white rounded-full animate-bounce"></div>
			<div className="w-2 h-2 bg-white rounded-full animate-bounce"></div>
			<div className="w-2 h-2 bg-white rounded-full animate-bounce"></div>
		</div>
	);
};

export default LoadingIndicator;
