/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
	// BookmarkAltIcon,
	// BriefcaseIcon,
	// ChartBarIcon,
	// CheckCircleIcon,
	// CursorClickIcon,
	// DesktopComputerIcon,
	// GlobeAltIcon,
	// InformationCircleIcon,
	MenuIcon,
	// NewspaperIcon,
	// OfficeBuildingIcon,
	PhoneIcon,
	// PlayIcon,
	// ShieldCheckIcon,
	// UserGroupIcon,
	// ViewGridIcon,
	XIcon,
	ClockIcon,
	BadgeCheckIcon,
	QuestionMarkCircleIcon,
	GiftIcon,
} from "@heroicons/react/outline";
// import { ChevronDownIcon } from "@heroicons/react/solid";

import logoWhite from "../images/Sydney_White.svg";
import logoDark from "../images/Sydney_Full.svg";

const menuItems = [
	{
		name: "Teeth Whitening Sessions",
		description:
			"Explore our different treatments and choose which one is best for your teeth.",
		href: "https://smilelabs.sydney/sessions",
		icon: ClockIcon,
	},
	{
		name: "Our Process",
		description: "Learn about our safe and painless process.",
		href: "https://smilelabs.sydney/process",
		icon: BadgeCheckIcon,
	},
	{
		name: "Frequently Asked Questions",
		description: "Find the answer to commonly asked questions.",
		href: "https://smilelabs.sydney/faq",
		icon: QuestionMarkCircleIcon,
	},
	{
		name: "Contact Us",
		description: "Drop us a line. We're here to help.",
		href: "https://smilelabs.sydney/contact-us",
		icon: PhoneIcon,
	},
	{
		name: "Purchase a Gift Card",
		description: "Brighter smiles all around",
		href: "https://smilelabs.sydney/gift-card",
		icon: GiftIcon,
	},
];
// const callsToAction = [
// 	{ name: "Watch Demo", href: "#", icon: PlayIcon },
// 	{ name: "View All Products", href: "#", icon: CheckCircleIcon },
// 	{ name: "Contact Sales", href: "#", icon: PhoneIcon },
// ];
// const company = [
// 	{ name: "About", href: "#", icon: InformationCircleIcon },
// 	{ name: "Customers", href: "#", icon: OfficeBuildingIcon },
// 	{ name: "Press", href: "#", icon: NewspaperIcon },
// 	{ name: "Careers", href: "#", icon: BriefcaseIcon },
// 	{ name: "Privacy", href: "#", icon: ShieldCheckIcon },
// ];
// const resources = [
// 	{ name: "Community", href: "#", icon: UserGroupIcon },
// 	{ name: "Partners", href: "#", icon: GlobeAltIcon },
// 	{ name: "Guides", href: "#", icon: BookmarkAltIcon },
// 	{ name: "Webinars", href: "#", icon: DesktopComputerIcon },
// ];
// const blogPosts = [
// 	{
// 		id: 1,
// 		name: "Boost your conversion rate",
// 		href: "#",
// 		preview:
// 			"Eget ullamcorper ac ut vulputate fames nec mattis pellentesque elementum. Viverra tempor id mus.",
// 		imageUrl:
// 			"https://images.unsplash.com/photo-1558478551-1a378f63328e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2849&q=80",
// 	},
// 	{
// 		id: 2,
// 		name: "How to use search engine optimization to drive traffic to your site",
// 		href: "#",
// 		preview:
// 			"Eget ullamcorper ac ut vulputate fames nec mattis pellentesque elementum. Viverra tempor id mus.",
// 		imageUrl:
// 			"https://images.unsplash.com/1/apple-gear-looking-pretty.jpg?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80",
// 	},
// ];

// function classNames(...classes) {
// 	return classes.filter(Boolean).join(" ");
// }

export default function NavHeader() {
	return (
		<Popover className="sticky top-0 z-50 bg-sl-blue-light">
			{({ open }) => (
				<>
					<div
						className="absolute inset-0 shadow z-30 pointer-events-none"
						aria-hidden="true"
					/>
					<div className="relative z-20">
						<div className="h-24 max-w-7xl mx-auto flex justify-between items-center pl-0 pr-4 py-5 sm:px-6 sm:py-4 lg:px-8 lg:justify-start lg:space-x-10">
							<div>
								<a
									href="https://smilelabs.sydney"
									className="flex"
								>
									<span className="sr-only">
										Smile Labs Sydney
									</span>
									<img
										className="w-44"
										src={logoWhite}
										alt="Smile Labs Sydney"
									/>
								</a>
							</div>
							<div className="-mr-2 -my-2 lg:hidden">
								<Popover.Button className="rounded-md p-2 mr-4 inline-flex items-center justify-between text-white hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 ">
									<span className="sr-only">Open menu</span>
									<MenuIcon
										className="h-6 w-6"
										aria-hidden="true"
									/>
								</Popover.Button>
							</div>
							<div className="hidden lg:flex-1 lg:flex lg:items-center justify-between">
								<Popover.Group
									as="nav"
									className="flex space-x-10"
								>
									<a
										href="https://smilelabs.sydney/sessions"
										className="text-xs xl:text-sm font-medium text-white hover:text-sl-blue-dark transition-colors duration-200"
									>
										Teeth Whitening Sessions
									</a>
									<a
										href="https://smilelabs.sydney/process"
										className="text-xs xl:text-sm font-medium text-white hover:text-sl-blue-dark transition-colors duration-200"
									>
										The Process
									</a>
									<a
										href="https://smilelabs.sydney/faq"
										className="text-xs xl:text-sm font-medium text-white hover:text-sl-blue-dark transition-colors duration-200"
									>
										FAQ's
									</a>
								</Popover.Group>
								<div className="flex items-center md:ml-12">
									<a
										href="https://smilelabs.sydney/contact-us"
										className="text-xs xl:text-sm font-medium text-white hover:text-sl-blue-dark transition-colors duration-200"
									>
										Contact Us
									</a>
									<a
										href="https://smilelabs.sydney/gift-card"
										className="ml-8 h-12 w-56 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-sl-green hover:bg-green-600 transition-colors duration-300"
									>
										Purchase a Gift Card
									</a>
								</div>
							</div>
						</div>
					</div>

					<Transition
						show={open}
						as={Fragment}
						enter="duration-200 ease-out"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="duration-100 ease-in"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<Popover.Panel
							focus
							static
							className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
						>
							<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
								<div className="pt-5 pb-6 px-5 sm:pb-8">
									<div className="flex items-center justify-between">
										<div>
											<img
												className="h-24 w-auto"
												src={logoDark}
												alt="Smile Labs Sydney"
											/>
										</div>
										<div className="-mt-12">
											<Popover.Button className="bg-white rounded-md p-2 flex-row items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sl-blue">
												<span className="sr-only">
													Close menu
												</span>
												<XIcon
													className="h-6 w-6"
													aria-hidden="true"
												/>
											</Popover.Button>
										</div>
									</div>
									<div className="mt-6 sm:mt-8">
										<nav>
											<div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
												{menuItems.map((item) => (
													<a
														key={item.name}
														href={item.href}
														className="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50"
													>
														<div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-sl-green text-white sm:h-12 sm:w-12">
															<item.icon
																className="h-6 w-6"
																aria-hidden="true"
															/>
														</div>
														<div className="ml-4 text-base font-medium text-sl-blue-dark">
															{item.name}
														</div>
													</a>
												))}
											</div>
											{/* <div className="mt-8 text-base">
												<a
													href="#"
													className="font-medium text-indigo-600 hover:text-indigo-500"
												>
													{" "}
													View all products{" "}
													<span aria-hidden="true">
														&rarr;
													</span>
												</a>
											</div> */}
										</nav>
									</div>
								</div>
								{/* <div className="py-6 px-5">
									<div className="grid grid-cols-2 gap-4">
										<a
											href="#"
											className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700"
										>
											Pricing
										</a>

										<a
											href="#"
											className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700"
										>
											Docs
										</a>

										<a
											href="#"
											className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700"
										>
											Company
										</a>

										<a
											href="#"
											className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700"
										>
											Resources
										</a>

										<a
											href="#"
											className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700"
										>
											Blog
										</a>

										<a
											href="#"
											className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700"
										>
											Contact Sales
										</a>
									</div>
									<div className="mt-6">
										<a
											href="#"
											className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
										>
											Sign up
										</a>
										<p className="mt-6 text-center text-base font-medium text-gray-500">
											Existing customer?{" "}
											<a
												href="#"
												className="text-indigo-600 hover:text-indigo-500"
											>
												Sign in
											</a>
										</p>
									</div>
								</div> */}
							</div>
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	);
}
