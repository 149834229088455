import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
// import { CheckIcon } from "@heroicons/react/outline";
import { BookingContext } from "./StepsNavigation";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const ProductCardAccordion = ({ product }) => {
	let history = useHistory();
	let bookingContext = useContext(BookingContext);

	const handleClick = (session) => {
		bookingContext.handleSessionChange(session);
		history.push("/datetime");
	};

	return (
		<Disclosure as="div" className="my-9" key={product.id}>
			{({ open }) => (
				<>
					<div className="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-md flex flex-col">
						<Disclosure.Button
							id={`session-type-accordion-${product.title}`}
							className="flex justify-between w-full  text-xl md:text-2xl font-bold text-left text-sl-blue rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75"
						>
							<div className="flex-1">
								<h3 className="text-xl font-semibold text-gray-900">
									{product.title}
								</h3>
								{product.popular ? (
									<p className="absolute top-0 py-1.5 px-4 bg-sl-green rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
										Most popular
									</p>
								) : null}
								<p className="mt-4 flex items-baseline text-gray-900">
									<span className="text-5xl font-extrabold tracking-tight">
										{product.price > 0
											? `$${product.price}`
											: "Free"}
									</span>
									<span className="ml-1 text-xl font-semibold">
										/ {product.duration_adv} mins
									</span>
								</p>
							</div>
							<ChevronUpIcon
								className={`${
									open ? "transform rotate-180" : ""
								} w-7 h-7 md:w-8 md:h-8 text-blue-500`}
							/>
						</Disclosure.Button>
						<Disclosure.Panel className=" pt-4 pb-2 text-gray-500">
							<p className="mt-6 text-gray-500">
								{product.description}
							</p>

							{/* Feature list */}
							{/* <ul className="mt-6 space-y-6">
								<li>
									<CheckIcon
										className="flex-shrink-0 w-6 h-6 text-indigo-500"
										aria-hidden="true"
									/>
									<span className="ml-3 text-gray-500">
										Feature
									</span>
								</li>
							</ul> */}
						</Disclosure.Panel>
						<button
							id={`btn-reserve-a-seat-${product.title}`}
							onClick={() => handleClick(product)}
							className={classNames(
								product.popular
									? "bg-sl-green text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:border-green-300"
									: "bg-indigo-50 text-sl-blue hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:border-blue-300",
								"mt-8 block w-full py-3 px-6 border border-transparent rounded-full text-center font-medium transition-colors duration-300"
							)}
						>
							Reserve a Seat
						</button>
					</div>
				</>
			)}
		</Disclosure>
	);
};

export default ProductCardAccordion;
