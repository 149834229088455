import React from "react";
import { useForm, Controller } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import LoadingIndicator from "../../components/LoadingIndicator";

const referrals = [
	{ label: "Returning client", value: "Returning client" },
	{ label: "Google", value: "Google" },
	{ label: "Facebook", value: "Facebook" },
	{ label: "Instagram", value: "Instagram" },
	{ label: "From a friend", value: "From a friend" },
	{ label: "Radio", value: "Radio" },
	{ label: "Television", value: "Television" },
];

const customStyles = {
	control: (base, state) => ({
		...base,
		borderRadius: 6,

		// borderColor: state.isFocused ? "#005B84" : "#D1D5DB",
		// borderWidth: state.isFocused ? 2 : 1,
		// "&:hover": { borderColor: "#005B84" },
		"*": {
			boxShadow: "none !important",
		},
		boxShadow: "#005B84",
		borderWidth: state.isFocused ? 2 : 1,
		borderColor: state.isFocused ? "#005B84" : "#D1D5DB",
		paddingTop: 1,
		paddingBottom: 1,
	}),
	input: (base, state) => ({
		...base,
		boxShadow: 0,
		borderWidth: 0,
	}),
};

const PersonalInfo = ({ onSubmit, defaultValues, isLoading }) => {
	const { register, handleSubmit, control } = useForm({
		defaultValues: defaultValues,
	});

	return (
		<div className="mt-5 pt-6 md:col-span-2 w-full">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="px-4 md:px-8 pt-8 bg-white rounded-lg shadow-lg">
					<h2 className="font-bold text-xl md:text-2xl text-sl-blue pb-6">
						Customer Details
					</h2>
					<div className="grid grid-cols-6 gap-6">
						<div className="col-span-6 sm:col-span-3">
							<label
								htmlFor="first_name"
								className="block text-sm font-medium text-gray-800"
							>
								First name *
							</label>
							<input
								type="text"
								name="first_name"
								id="first_name"
								maxLength={50}
								autoComplete="given-name"
								required
								aria-required
								{...register("first_name")}
								className="mt-1 focus:ring-sl-blue focus:border-sl-blue block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
							/>
						</div>

						<div className="col-span-6 sm:col-span-3">
							<label
								htmlFor="last_name"
								className="block text-sm font-medium text-gray-800"
							>
								Last name *
							</label>
							<input
								type="text"
								name="last_name"
								id="last_name"
								maxLength={50}
								required
								aria-required
								autoComplete="family-name"
								{...register("last_name")}
								className="mt-1 focus:ring-sl-blue focus:border-sl-blue block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
							/>
						</div>

						<div className="col-span-6 sm:col-span-4">
							<label
								htmlFor="email"
								className="block text-sm font-medium text-gray-800"
							>
								Email address *
							</label>
							<input
								type="email"
								name="email"
								id="email"
								maxLength={50}
								required
								aria-required
								autoComplete="email"
								{...register("email")}
								className="mt-1 focus:ring-sl-blue focus:border-sl-blue block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
							/>
						</div>

						<div className="col-span-6 sm:col-span-4">
							<label
								htmlFor="mobile"
								className="block text-sm font-medium text-gray-800"
							>
								Mobile *
							</label>
							<input
								type="tel"
								name="mobile"
								id="mobile"
								maxLength={15}
								minLength={10}
								placeholder="0400123456"
								pattern="[0-9]{4}\s?[0-9]{3}\s?[0-9]{3}"
								title="Australia mobile number e.g. 0400123456"
								required
								aria-required
								autoComplete="tel"
								{...register("mobile")}
								className="mt-1 focus:ring-sl-blue focus:border-sl-blue block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
							/>
						</div>

						<div className="col-span-6 sm:col-span-4">
							<label
								htmlFor="referral"
								className="block text-sm font-medium text-gray-800"
							>
								Where did you hear about us?
							</label>
							<Controller
								name="referral"
								control={control}
								defaultValue={false}
								rule={{ maxLength: 50 }}
								render={({ field }) => (
									<CreatableSelect
										closeMenuOnSelect={false}
										isMulti
										options={referrals}
										name="referral"
										id="where-did-you-hear"
										styles={customStyles}
										{...field}
										className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
									/>
								)}
							/>
						</div>
					</div>
					<div className="px-4 md:px-0 py-8 pt-12 text-center md:text-right sm:px-6">
						<button
							id={`btn-submit-customer-details`}
							type="submit"
							disabled={isLoading}
							className="inline-flex h-12 w-48 sm:w-60 justify-center items-center px-6 py-3 border border-transparent text-md font-medium rounded-full shadow-sm text-white bg-sl-blue hover:bg-sl-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sl-blue transition-colors duration-200"
						>
							{isLoading ? <LoadingIndicator /> : "Next"}
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default PersonalInfo;
